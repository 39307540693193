import { changeOpacity } from "./utils";

function openYoutubeModal(event, title, videoId) {
  event.preventDefault();
  const scrollbarWidth = window.innerWidth - document.body.clientWidth;
  document.body.style["overflow"] = "hidden";
  document.body.style["padding-right"] = `${scrollbarWidth}px`;

  const headerNoticeWrap = document.getElementById("HeaderNoticeWrap");
  if (headerNoticeWrap) {
    headerNoticeWrap.style["padding-right"] = `${scrollbarWidth}px`;
  }

  const modal = document.getElementById("YoutubeModal");
  const heading = document.getElementById("YoutubeModal__heading");
  heading.innerText = title;

  if (window.YTPlayer === null) {
    var tag = document.createElement("script");
    tag.src = "https://www.youtube.com/iframe_api";
    var firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    window.YTPlayerVideoId = videoId;
  } else {
    window.YTPlayerVideoId = videoId;
    playVideo(videoId);
  }

  modal.classList.remove("hidden");
  changeOpacity("YoutubeModal__bg", 100);

  gtag("send", "event", "openYoutubeModal", title);

  return false;
}

window.openYoutubeModal = openYoutubeModal;

function closeYoutubeModal(event) {
  event.stopPropagation();

  const target = event.target;
  const closeIcon = target.closest("#YoutubeModal__close");

  if (target.id == "YoutubeModal__bg" || closeIcon) {
    document.body.style["overflow"] = "visible";
    document.body.style["padding-right"] = "0px";
    const headerNoticeWrap = document.getElementById("HeaderNoticeWrap");
    if (headerNoticeWrap) {
      headerNoticeWrap.style["padding-right"] = "0px";
    }

    const modal_bg = document.getElementById("YoutubeModal__bg");
    modal_bg.style["opacity"] = 0;

    setTimeout(function () {
      document.getElementById("YoutubeModal").classList.add("hidden");
      window.YTPlayer.destroy();
    }, 200);
  }

  return false;
}

window.closeYoutubeModal = closeYoutubeModal;

// Youtube api
function onYouTubeIframeAPIReady() {
  document.getElementById("YoutubeModal__spinner").classList.add("hidden");
  document.getElementById("YoutubeModal__iframe").classList.remove("hidden");

  playVideo(window.YTPlayerVideoId);
}

function playVideo(videoId) {
  window.YTPlayer = new YT.Player("YoutubeModal__player", {
    height: "360",
    width: "640",
    videoId: videoId,
    events: {
      onReady: function (event) {
        event.target.playVideo();
      },
    },
  });
}

window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;

function initYoutubeModal() {
  window.YTPlayer = null;
  window.YTPlayerVideoId = null;

  const modal = document.getElementById("YoutubeModal__bg");
  const modal_close = document.getElementById("YoutubeModal__close");

  if (modal) {
    modal.addEventListener("click", closeYoutubeModal);
    modal_close.addEventListener("click", closeYoutubeModal);
  }
}

initYoutubeModal();
